
.Project-title {
    text-align: center;
    width: 100%;
    font-family: Crimson Pro;
    font-size: 2em;
    color: #346283;
    font-weight: bold;
}

.home-header {
    display: flex;
    /* background-image: linear-gradient(to left, var(--blue-1), var(--blue-2)); */
    background-color: #3E81B1;
    height: 4em;
    justify-content: space-between;
    align-items: center;
}
.home-header h1 {
    color: var(--light);
    padding-left: 1em;
    font-family: Crimson Pro;
    font-size: 1.8em;
    text-decoration: none;
}
.home-header button {
    font-size: 1.1em;
    margin: 2em;
}
.home-header a {
    text-decoration: none;
    
}