
.body-container {
    height: calc(175vh - 10rem);
    background-image: url(assets/bkgd-arc.svg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
}
.body-card-left {
    background-image: url(assets/card1.svg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-left: 2rem;
    margin-top: 3rem;
    display: flex;
    align-self: start;
    flex-direction: column;
}
.body-card-left p{
    font-size: 1.18em;
    padding: 4em;
    padding-right: 4.5em;
    padding-top: 0;
    margin: 0;
    color: white;
    line-height: 1.8em;
}
.body-card-left h3 {
    color: white;
    font-weight:bolder;
    font-size: 1.6em;
    align-self: left;
    margin: 0;
    padding: 2.9em;
    padding-top: 1.8em;
    padding-bottom: 1em;
}
.body-card-right {
    background-image: url(assets/card2.svg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-right: 2rem;
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    align-self: end;
    animation-name: pulse;
    animation-duration: 9s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-delay: 4s;
}
.body-card-right p{
    font-size: 1.18em;
    padding: 4em;
    padding-right: 4.5em;
    padding-top: 0;
    margin: 0;
    color: white;
    line-height: 1.8em;
}
.body-card-right h3 {
    color: white;
    font-weight:bolder;
    font-size: 1.6em;
    align-self: left;
    margin: 0;
    padding: 2.9em;
    padding-top: 1.8em;
    padding-bottom: 1em;
}