.header-container h1 {
    font-family: Crimson Pro;
    font-style: normal;
    font-weight: 400;
    font-size: 2.6em;
    margin: 0;
    color: white;
    padding-left: 4.5em;
    padding-top: 1.3em;
}
.header-container {
    display: flex;
    flex-direction: row;
    align-items: top;
    justify-content: space-between;
    height: 10rem;
    background-image: url(assets/Header-back.svg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin:0;
    border:0;
}

.header-right{
    height:10em;
    display:flex;
    flex-direction: column;
    align-items: center;
    margin-right:10em;
}
.header-right p:first-child {
    margin-top:1.5em;
}

.header-right p{
    color:white;
    font-weight: 400;
    font-size: 1.15em;
    margin:0;
    margin-top: 0.25em;
}
.header-right a, .header-right a:visited, .header-right a:active{
    color: var(--link);
    font-weight:bold;
}
.header-right a:hover {
    cursor: pointer;
    text-decoration: underline;
}

.header-socials {
    display: flex;
    flex-direction: row;
    align-items: right;
    margin-top: 0.25em;
}

.header-social-ico {
    width:1.75em;
    height:1.75em;
    margin:0;
    padding:0;
    align-self: center;
    justify-self: center;
}
.header-social-circle {
    background-color: var(--secondary);
    height: min-content;
    font-size:1em;
    padding:0.2em;
    margin:0.5em;
    border-radius: 1.5em;
    color:white;
    border:0;
    display: flex;
}
.header-social-circle:hover {
    transform: translateY(0.15em);
    box-shadow: 0em 0em 0em #0000004d;
    transition: 0.25s;
    cursor: pointer;
}
.header-social-circle:not(:hover) {
    transform: translateY(0em);
    box-shadow: 0em .15em .15em #0000004d;
    transition: 0.25s;
}