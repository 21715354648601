:root {
  --blue-1: #61B5F2;
  --blue-2: #3a90ce;
  --link: #F5FDC6;
  --gray-1: #C0C0BF;
  --gray-2: #EFEFEF;
  --secondary: #F0DED0;
  --text: #346283;

  --light: #f3f8ff;
  --dark: #264D69;

  --card: #d8e8f4;

}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p, button{
  color: var(--text);
  font-family: Mukta;
}
h3, h2, h1, h4 {
  color: var(--text);
  font-family: Crimson Pro;
}
button {
  border-radius: 0.5em;
  border: 0;
  padding: 0 0.8em;
  background: var(--secondary);
}
button:hover {
  transform: translateY(0.125em);
  cursor: pointer;
  transition: 0.25s;
}
button:not(:hover) {
  transform: translateY(0);
  transition: 0.25s;
}

#root {
  background-color: var(--light);
}