.row-content-container {
    margin-top: 8em;
    display: flex;
    flex-direction: column;
    gap: 10em;
    align-items: center;
    padding: 0 8em;
    padding-bottom: 10em;
}

.Project-Row {
    display:flex;
    flex-direction: row;
    gap: 5rem;
    max-width: 1500px;
    align-items: center;
}
.Project-Row.left-image.crop img{
    mask-image: url(assets/card1.svg); 
    mask-size: 100% 100%;
    justify-self: start;
}
.Project-Row.right-image.crop img{
    mask-image: url(assets/card2.svg);
    mask-size: 100% 100%;
}

.Project-Row.none img {
    border-radius: 0.4em;
}

.Project-Row-List {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}
.Project-Row-Description {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    max-width: 55rem;
}
.Project-Row-desc {
    margin:0;
    font-size: 1.32em;
    text-align: center;
}
.item-prefix {
    background: var(--blue-1);
    color: white;
    font-size: 1.45em;
    font-family: PT Sans;
    width: 2em;
    height: 2em;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1em;
    margin:0;
    margin-right: 1.5em;
    align-self: center;
}
.Project-Row-List div {
    display: flex;
    flex-direction: row;
    color: black;
    align-items: center;
    justify-self: start;
    border-radius: 4em;
    padding-right: 2em;
    margin:0.5em;
    width: 100%;
    background-image: linear-gradient(to right,#5fb5f26b, #b8def92e 15%, #b8def92e);
}

.project-list-p {
    margin: 0;
    width: 100%;
    font-size: 1.15rem;
}

.img-card  {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 1em;
    padding: 0.5em;
    box-shadow: var(--gray-1) 3px 3px 15px 1px;
}

.img-card img {
    width: 100%;
    border-radius: 0.5rem;
}