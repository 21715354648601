
.home-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--light); 
    margin: 0;
    padding: 0;
    height: calc(400vh - 4em);
    gap: 13em;
}
.home-outside-card {
    /* flex: 0 0 40%; */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 2.9em;
    padding: 0.5em;
    box-shadow: var(--gray-1) 3px 3px 15px 1px;
}
.home-card {
    display: flex;
    flex-direction: column;
    background-color: var(--card);
    border-radius: 38px;
    align-items: center;
    padding: 2em;
}
.home-card h2 {
    font-size: 4.5em;
    font-weight: normal;
    margin-top: 0.4em;
    margin-bottom: 0.8em;
    color: var(--dark);

}
.home-card h3 {
    font-size: 2em;
    font-weight: normal;
    margin-top: 0.4em;
    margin-bottom: 0.8em;
    color: var(--dark);
}
.home-card p {
    font-family: Mukta;
    font-size: 1.55em;
    margin: 0;
    padding: 0 5em;
    color: var(--dark);
    text-align: center;
    line-height: 1.2em;

}
#home-about-me {
    position: relative;
}
#home-about-me:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.075;
    background-image: url(brain_circuit.png);
    background-size: cover;
    border-radius: 38px;
}
.home-card a {
    font-family: Mukta;
    color: var(--text);
    font-weight: bold;
    text-decoration: none;
}
.home-card a:hover {
    text-decoration: underline;
}

.home-proj-row {
    display: flex;
    flex-direction: row;
    width:100%;
    gap: 8em;
    align-items: center;
    padding: 1em;
}
.home-proj-row button{
    font-size: 1.5em;
    margin: 0 0 0 2em;
    flex: 0 0 20%;
    text-wrap: nowrap;
}
.home-proj-row p {
    margin: 0;
    font-size: 1.3em;
    padding: 0 2.5em 0 0;
}
.home-proj-row img {
    border-radius: 10px;
}