

.weather-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: -5rem;
}
.weather-links p {
    font-size: 1.15em;
    margin: 0.5em;
}
.weather-links button {
    font-size: 1em;
}

.photo-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 3em;
    align-items: center;
    justify-content: center;
}